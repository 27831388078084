<template>
  <div>
    <cabecalho-pagina
      :titulo="$t('modulos.operacao.titulos.entrega')"
      sem-botoes
      sem-registros
    />
    <v-chip
      class="mt-1"
      small
    >
      {{ `Ordem Serviço: ${ordemServico.codigo}` }}
    </v-chip>
    <v-form
      ref="form"
      class="row mt-4"
    >
      <input-text
        :value="ordemServico?.clienteParticipante?.apelido ?? ordemServico?.clienteParticipante?.nome"
        class="col-12 col-md-3"
        :label="
          $t('modulos.ordem_servico.formulario.dados_ordem_servico.cliente')
        "
        disabled
      />
      <input-text
        :value="ordemServico?.solicitanteParticipante?.nome"
        disabled
        :label="
          $t('modulos.ordem_servico.formulario.dados_ordem_servico.solicitante')
        "
        :placeholder="$t('geral.inputs.selecione')"
        class="col-12 col-md-3"
      />
      <input-date
        :value="ordemServico?.dataRecebimento"
        disabled
        :label="
          $t(
            'modulos.ordem_servico.formulario.dados_ordem_servico.data_recebimento'
          )
        "
        class="col-12 col-md-3"
      />
      <input-date
        :value="ordemServico?.dataPrevisaoEntrega"
        :label="
          $t(
            'modulos.ordem_servico.formulario.dados_ordem_servico.data_previsao'
          )
        "
        :placeholder="$t('geral.inputs.selecione')"
        class="col-12 col-md-3"
        disabled
      />
      <input-text
        :value="ordemServico?.tipoEnvio?.nome"
        :label="
          $t(
            'modulos.ordem_servico.formulario.dados_ordem_servico.tipo_envio_recebimento'
          )
        "
        class="col-12 col-md-3"
        disabled
      />
      <input-select
        v-model="form.tipoEnvioEntrega"
        :options="opcoes.tipoEnvioEntrega"
        :label="$t('modulos.ordem_servico.formulario.dados_ordem_servico.tipo_envio_entrega')"
        :placeholder="$t('geral.inputs.selecione')"
        class="col-12 col-md-3"
        retornar-objeto
        obrigatorio
      />
      <input-date
        v-model="form.dataAgendamento"
        :label="$t('modulos.envio_material.formulario.data_agendamento')"
        :placeholder="$t('geral.inputs.selecione')"
        class="col-12 col-md-3"
        :allowed-days="diasEnvio"
        apenas-datas-futuras
        :obrigatorio="!!form.tipoEnvioEntrega?.flagVisivelRoteirizacao"
        :disabled="!form.tipoEnvioEntrega?.flagVisivelRoteirizacao || !diasEnvio"
      />
      <input-select
        v-model="form.contatoSolicitanteId"
        :options="opcoes.contato"
        class="col-12 col-md-3"
        :label="$t('modulos.envio_material.formulario.contato_solicitante')"
        :placeholder="$t('geral.inputs.selecione')"
        :loading="loading.contatoLoading"
        :obrigatorio="!!form.tipoEnvioEntrega?.flagVisivelRoteirizacao"
        :disabled="!form.tipoEnvioEntrega?.flagVisivelRoteirizacao"
      />
      <input-text
        v-model="form.nomeTransportadora"
        :label="
          $t(
            'modulos.ordem_servico.formulario.dados_ordem_servico.nome_transportadora'
          )
        "
        class="col-12 col-md-3"
      />
      <input-select
        v-model="form.embalagemEntregaId"
        :options="opcoes.embalagens"
        :loading="loading.embalagensLoading"
        obrigatorio
        class="col-12 col-md-3"
        :label="$t('modulos.operacao.formulario.embalagem_entrega')"
      />
      <input-text
        v-model="form.quantidadeVolumes"
        class="col-12 col-md-3"
        :label="$t('modulos.operacao.formulario.qtd_volumes')"
        numero-minimo="1"
        type="number"
      />
      <input-text
        v-model="form.peso"
        class="col-12 col-md-3"
        :label="$t('modulos.operacao.formulario.peso_kg')"
        type="number"
      />
      <input-text
        v-model="form.nomeQuemRetirou"
        class="col-12 col-md-6"
        :label="$t('modulos.operacao.formulario.nome_pessoa_retirou')"
      />
      <input-text
        v-model="form.telefoneQuemRetirou"
        class="col-12 col-md-6"
        :label="$t('modulos.operacao.formulario.telefone')"
      />
    </v-form>
    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end mt-4">
      <botao-padrao
        outlined
        color="secondary"
        class="mr-2"
        @click="cancelar"
      >
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao @click="finalizar">
        {{ $t('geral.botoes.finalizar') }}
      </botao-padrao>
    </div>
  </div>
</template>
<script>
import { DropdownModel } from '@/common/models/geral/DropdownModel';
import CabecalhoPagina from '@components/layout/CabecalhoPagina.vue';
import OrdemServicoService from '@common/services/cadastros/OrdemServicoService';
import TipoEnvioService from '@/common/services/cadastros/TipoEnvioService';
import ParticipanteService from '@/common/services/cadastros/ParticipanteService';
import EmbalagensService from '@/common/services/cadastros/EmbalagensService';
import OperacaoService from '@/common/services/cadastros/OperacaoService';
import moment from 'moment';
import {sortBy, cloneDeep} from 'lodash';

export default {
  components: {
    CabecalhoPagina
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      ordemServico: {},
      form: {
        tipoEnvioEntrega: null,
        dataAgendamento: null,
        contatoSolicitanteId: null,
        nomeTransportadora: null,
        embalagemEntregaId: null,
        quantidadeVolumes: null,
        peso: null,
        nomeQuemRetirou: null,
        telefoneQuemRetirou: null,
      },
      opcoes: {
        tipoEnvioEntrega: [],
        contato: [],
        embalagens: [],
      },
      loading: {
        tipoEnvioEntregaLoading: true,
        contatoLoading: true,
        embalagensLoading: true,
      },
      diasEnvio: []
    };
  },
  mounted: async function () {
    await this.buscarOrdemServico();
    this.buscarTiposEnvioRecebimento();
    this.buscarTiposEnvioEntrega();
    this.buscarEmbalagens();
  },
  methods: {
    buscarOrdemServico: async function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      await OrdemServicoService.buscar(this.id)
        .then((res) => {
          res.data.tipoEnvio = {};
          this.ordemServico = res.data;
          this.buscarDiasEnvio(this.ordemServico.clienteParticipante.id)
          this.buscarContato(this.ordemServico.clienteParticipante.id)
          // PREENCHER ALGUNS CAMPOS COM BASE NA ORDEM DE SERVIÇO/ENTREGA
          this.form.contatoSolicitanteId = res.data.entrega?.contatoSolicitante?.id ?? res.data.clienteParticipanteContatoId;
          this.form.embalagemEntregaId = res.data.entrega?.embalagem?.id ?? res.data.embalagemId;
          this.form.peso = res.data.entrega?.peso;
          this.form.quantidadeVolumes = res.data.entrega?.quantidadeVolumes;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    buscarTiposEnvioRecebimento() {
      return TipoEnvioService.listar().then((res) => {
        this.ordemServico.tipoEnvio = res.data.items.find(
          (el) => el.id === this.ordemServico?.tipoEnvioId
        );
      });
    },
    buscarTiposEnvioEntrega() {
      return TipoEnvioService.listar().then((res) => {
        const tipos = cloneDeep(res.data.items);
        this.opcoes.tipoEnvioEntrega = tipos.map(el => {
            const text = el.flagVisivelRoteirizacao ? el.nome + ' - Roteirização' : el.nome;
            return {
              ...el,
              value: el.id,
              text}
        })
        this.loading.tipoEnvioEntregaLoading = false;
      });
    },
    buscarDiasEnvio(id) {
      this.diasEnvio = []
      ParticipanteService.buscar(id).then((res) => {
        if(res.data?.flagEnvioMaterialSegundaFeira == true)
          this.diasEnvio.push(0);
        if(res.data?.flagEnvioMaterialTercaFeira == true)
          this.diasEnvio.push(1);
        if(res.data?.flagEnvioMaterialQuartaFeira == true)
          this.diasEnvio.push(2);
        if(res.data?.flagEnvioMaterialQuintaFeira == true)
          this.diasEnvio.push(3);
        if(res.data?.flagEnvioMaterialSextaFeira == true)
          this.diasEnvio.push(4);

        if(this.diasEnvio.length == 0)
          {
            this.toastAlerta(this.$t('modulos.envio_material.erros.cliente_sem_dias_envio'))
            this.diasEnvio = null
            this.form.dataAgendamento = null
            this.$refs.form.resetValidation()
            return
          }

        this.buscarDataMaisProxima(this.diasEnvio)

      })


    },
    buscarDataMaisProxima(dias){
      const hoje = moment();
      let datasCaluladas = []

      dias.forEach(dia => {
        const proximoDataDisponivel = hoje.clone().day(dia+1)
        if(proximoDataDisponivel.isBefore(hoje))
          proximoDataDisponivel.add(1, 'week')

        datasCaluladas.push(proximoDataDisponivel)

      });
      let data = sortBy(datasCaluladas, (data) => data.diff(hoje))[0]
      this.form.dataAgendamento = data.format('YYYY-MM-DD')
    },
    abreModalAdicionaClienteContato() {
      this.$refs["modal-criacao-cliente"].abrirModal(this.form.clienteParticipanteId?.value);
    },
    buscarContato(id) {
      return ParticipanteService.buscaParticipantesContatosPorId(id).then((res) => {
        this.opcoes.contato = new DropdownModel(res.data);
        this.loading.contatoLoading = false;
      });
    },
    buscarEmbalagens() {
      return EmbalagensService.listar().then((res) => {
        this.opcoes.embalagens = new DropdownModel(res.data);
        this.loading.embalagensLoading = false;
      });
    },
    cancelar() {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: 'operacao' });
      });
    },
    finalizar(){
        if(!this.$refs.form.validate()) return;
        this.confirmarSalvar().then(() => {
          this.salvarEntregaOS()
        });
    },
    salvarEntregaOS(){
        const retorno = cloneDeep(this.form);
        retorno.tipoEnvioId = retorno.tipoEnvioEntrega.id;
        // retorno.quantidadeVolumes = retorno.quantidadeVolumes ?? 1;

        this.$store.dispatch('Layout/iniciarCarregamento');
        OperacaoService.salvarEntregaOrdemServico(this.id, retorno).then(() => {
          this.toastSucesso(this.$t('modulos.operacao.entrega_instrumento_sucesso'))
          this.$router.push({ name: 'operacao' });
        }).catch((err) => {
            this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        }).finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        })
    }
  },
};
</script>
